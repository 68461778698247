.lightGrayBackground {
    background-color: #f3f3f3 !important;
}
.row-5{
    display: -ms-grid;
    display: grid;
    /* grid-template-columns: repeat(5,20%); */
    -ms-grid-columns:repeat(5,20%);
    }
.margin_10{
    margin:10px !important;
    }   
.margin_r_16 {
    margin-right: 16px;
    }
.margin_b_20 {
    margin-bottom: 20px;
    } 
            
.inputDisabled{
    background-color: #ccc !important; 
    color: #666;
    cursor: not-allowed;
  }
  .grid-container {
    display: grid;
    grid-template-columns: auto auto ;
    gap: 10px;
    /* width: 50vw; */
    /* margin: 0 auto; */
  }
  .grid-row {
    grid-column-start: 1;
    grid-column-end: 3;
  }
  .main-container {
    display: grid;
    grid-template-columns: auto auto ;
    gap: 15px;
    width: 80vw;
    margin: 0 auto;
  }
  .button-group  button{
    border: none;
    color: white;
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    -webkit-transition-duration: 0.4s; /* Safari */
    transition-duration: 0.4s;
    border-radius: 12px;

  }
.button-group{ 
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 10px;
    margin-top: 10px;
    text-align:right
    }
.save{
    margin-bottom: 100px;
    background-color: #4CAF50;
}
.duplicate{
    background-color: #2196F3;
    /* margin-right:1rem; */
}
fieldset{
    box-shadow:0px 2px 5px rgba(50, 50, 50, 0.3);
    border:none;
    padding: 1rem;
}
legend{
  font-weight: bolder;
    background-color: #fff;
}
.label-required::after{
    content: "*";
  color: #e73c33;
  font-size: large;
}