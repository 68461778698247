.mainContainer {
  display: grid;
  width: 70%;
  margin: 4rem auto;
}
.block{
  display: none;
}
.manageTimeSlot {
  border: 1px solid #cccccc;
  background: #f6f6f6;
  height: 2rem;
  display: grid;
  align-items: center;
  padding: 0rem 1rem;
  border-bottom: none;
  font-size: 1rem;
  font-weight: 650;
  text-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.9px;
}
.innerBox {
  border: 1px solid #cccccc;
  padding: 2rem;
  display: grid;
}
.slotBookContainer {
  width: -webkit-fill-available;
  height: fit-content;
  border: 1px solid #dddddd;
  border-radius: 0.3125rem;
  background: #f6f6f6;
  padding: 1.5rem;
}
.selectDate {
  color: #282828;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.0088rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.maskingDataContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.radioContainer{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.monthName {
  color: #008000;
  padding: 0rem 1rem;
  margin-right: auto;
}
.dateBox {
  width: 3.5rem;
  height: 3.0625rem;
  background: #ffffff;
  border-radius: 0.125rem;
  color: #282828;
  display: grid;
  align-items: center;
  justify-items: center;
  font-size: 0.7rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.0075rem;
  margin: 2rem 6.4rem 0rem 0rem;
}
.dateBoxColor {
  background: #c5322c !important;
  color: #ffffff !important;
  font-weight: 600;
}
.timingSlot {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  border-bottom: 2px solid #dddddd;
}
.timingSlot:last-child {
  border-bottom: none;
}
.timeBox {
  width: 7rem;
  height: 2.2rem;
  background: #ffffff;
  border-radius: 0.125rem;
  font-size: 0.85rem;
  letter-spacing: 0.14px;
  color: #000;
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  margin: 2rem 3rem 0rem 0rem;
}
.timeBoxColor {
  background: #c5322c !important;
  color: #ffffff !important;
}
.saveBtn {
  /* background: rgb(105, 118, 148);
  color: #fff; */
  color: rgb(255, 255, 255);
  background-color: rgb(92, 184, 92);
  border-color: rgb(76, 174, 76);
}
.dateSlot {
  display: flex;
  flex-wrap: wrap;
  /* margin: 2rem 0.625rem 0rem 0rem; */
}
.dateTextBox{
  margin: 2rem 0.625rem 0rem 0rem;
  width: 60%;
}
.dateInputBox{
  background: #ffffff;
  border-radius: 0.125rem !important;
  color: #282828 !important;
  display: grid;
  align-items: center;
  justify-items: center;
}

.toggle-switch {
  position: relative;
  width: 75px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
}
.toggle-switch-checkbox {
  display: none;
}
.toggle-switch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 0 solid #ccc;
  border-radius: 20px;
  margin: 0;
}
.toggle-switch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
}
.toggle-switch-inner::before, .toggle-switch-inner::after {
  display: block;
  float: left;
  width: 50%;
  height: 34px;
  padding: 0;
  line-height: 34px;
  font-size: 14px;
  color: white;
  font-weight: bold;
  box-sizing: border-box;
}
.toggle-switch-inner:before {
  content: "Yes";
  text-transform: uppercase;
  padding-left: 10px;
  background-color: rgb(92, 184, 92);;
  color: #fff;
}
.toggle-switch-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-disabled::before {
  background-color: #ccc;
  cursor: not-allowed;
}
.toggle-switch-inner::after {
  content: "No";
  text-transform: uppercase;
  padding-right: 10px;
  background-color: #ccc;
  color: #fff;
  text-align: right;
}
.toggle-switch-switch {
  display: block;
  width: 24px;
  margin: 5px;
  background: #fff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  border: 0 solid #ccc;
  border-radius: 20px;
  transition: all 0.3s ease-in 0s;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
  margin-left: 0;
}
.toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
  right: 0px;
}
.font-10p{
  font-size: 10px;
}
.width-12r{
  width:12rem;
}
.small-gap-2 {
  margin-left: -2% !important;
}
.small-gap-4 {
  margin-left: -4% !important;
}
.active-inactive-text::after {
  content: "Inactive" !important;
}
.active-inactive-text::before {
  content: "Active" !important;
}

.active-inactive-text::after, .active-inactive-text::before {
  padding-left: 0 !important;
  text-align: center !important;
}