.widthMainComp {
  height: auto;
  width: 100%;
  /* position: absolute;
  top: 180px; */
}
.middleUpperTable {
  border: 1px solid #d8dbe0;
  border-collapse: collapse;
}
.middleUpperTH {
  border: 1px solid #d8dbe0;
  border-collapse: collapse;
  height: 2rem;
  font-size: 14px;
  color: #4f5d73;
  text-transform: uppercase;
}
.middleUpperTD {
  border: 1px solid #d8dbe0;
  /* border-top: 1px solid #d8dbe0;
  border-bottom: 1px solid #d8dbe0; */
  border-collapse: collapse;
  height: 2rem;
  color: #4f5d73;
  font-size: 13px;
}
/* .middleUpperTD:nth-child(even) {
  background-color: #ffffff;
} */
.width1TH {
  width: 25%;
}
.width7TH {
  width: 15%;
}
.width6TH {
  width: 18%;
}
.width3TH {
  width: 12%;
}
.width2TH {
  width: 10%;
}
.width4TH {
  width: 6%;
}

.middleUpperTR:hover {
  cursor: pointer;
  background: #eeeeee;
  color: #444444;
  font-family: "hurme1semibold";
  box-shadow: 0px 2px 5px rgba(50, 50, 50, 0.3);
  -webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, 0.3);
}

.btnTable {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.scrollMiddle::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
  display: block !important;
}
.scrollMiddle::-webkit-scrollbar-track {
  background: #ffffff;
}
.scrollMiddle::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 15px;
}

/* table,
th,
td {
  border: 1px solid #ddd;
  border-collapse: collapse;
}
td,
th {
  width: 12.5%;
  height: 4rem;
}
th {
  font-size: 24px;
  color: #000;
} */
/* --------DashBoard.js-------- */
.MainGridComponent {
  display: flex;
  flex-direction: row;
}
.widthDashboard100 {
  display: block;
  width: 100%;
  max-width: 15%;
  /* animation: slide 5s; */
}
#dashboardmainleft {
  width: 100%;
}
.marginDashboard1 {
  margin: 0 !important;
}

@keyframes slide {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
@keyframes slider {
  0% {
    width: 100%;
  }
  100% {
    width: 0%;
  }
}

.bottomToggleButton {
  position: fixed;
  z-index: 100;
  bottom: 45px;
  margin-left: 20px;
  background: rgb(105, 118, 148);
  color: #fff;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.65rem;
}
.arrowToggle {
  transition: all 1s ease 0s;
  width: 15px;
  height: 25px;
}
.inputDealPrice {
  background: none !important;
  border-radius: unset !important;
  outline: none !important;
  margin: 0 !important;
  box-shadow: none !important;
  font-size: 15px !important;
}

/*------Left Panel------ */
.InternalTop {
  background: #2a3f54 !important;
  text-align: center;
  border-top: 1px solid #243442;
  border-bottom: 1px solid #243442;
}
.flexInternal {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.secondInternal {
  background: #2a3f54 !important;
  top: 170px !important;
  padding: 1rem 0rem !important;
}
.tabsLeft {
  margin: 4% 0;
  width: 94% !important;
  margin-left: 3%;
  color: #e7e7e7 !important;
  border-top: 1px solid #243442;
  border-bottom: 1px solid #243442;
  background: linear-gradient(#334556, #2c4257), #2a3f54 !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0 1px 0,
    inset rgba(255, 255, 255, 0.16) 0 1px 0 !important;
}
.tabsLeft:hover {
  background: rgba(255, 255, 255, 0.5) !important;
  color: #ffffff !important;
}

/* ------topbar.js-------- */
.avatarLogo {
  vertical-align: middle;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  cursor: pointer;
}
.dropdown {
  position: relative;
  margin-right: 2rem;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  min-width: 90px;
  box-shadow: 0px 8px 16px 8px rgba(0, 0, 0, 0.2);
  z-index: 21;
  margin-left: -2rem;
  margin-top: -0.25rem;
}
.dropdown-content a {
  width: 100%;
  line-height: 0 !important;
  display: block !important;
  margin-left: 0px !important;
  padding: 1rem !important;
  border-radius: 1px !important;
}
.dropdown-content a:hover {
  background-color: #ddd;
}

.dropdown:hover .dropdown-content {
  display: block;
}
.empty{
  padding: 100px 0px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  width: 86%;
}

.vertical-page-video[poster] {
  height: 120px;
  width: 180px;
}