.block{
	display: none;
}

.mt-54 {
	margin-top: 54px;
}

.training-level-heading {
	font-size: 30px;
	font-weight: 700;
	margin-left: 32px;
}

.level-toggle-section {
	margin-right: 24px;
}

.training-level-mandatory {
	margin-top: 24px;
	margin-right: 12px;
	font-weight: 600;
	font-size: 22px;
}

/* Flex */
.flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

/* Toggle */
.toggle-switch {
	position: relative;
	width: 75px;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
}
.ta-toggle-switch-checkbox {
	display: none;
}
.ta-toggle-switch-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: 0 solid #ccc;
	border-radius: 20px;
	margin: 0;
}
.ta-toggle-switch-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: margin 0.3s ease-in 0s;
}
.ta-toggle-switch-inner::before,
.ta-toggle-switch-inner::after {
	display: block;
	float: right;
	width: 50%;
	height: 28px;
	padding: 0;
	line-height: 28px;
	font-size: 12px;
	color: white;
	font-weight: bold;
	box-sizing: border-box;
}
.ta-toggle-switch-inner:before {
	content: "Yes";
	text-transform: uppercase;
	padding-left: 10px;
	background-color: rgb(92, 184, 92);
	color: #fff;
}
.ta-toggle-switch-disabled {
	background-color: #ccc;
	cursor: not-allowed;
}
.ta-toggle-switch-disabled::before {
	background-color: #ccc;
	cursor: not-allowed;
}
.ta-toggle-switch-inner::after {
	content: "Yes";
	text-transform: uppercase;
	padding-left: 10px;
	background-color: rgb(92, 184, 92);
	color: #fff;
	text-align: left;
}
.ta-toggle-switch-switch {
	display: block;
	width: 0px;
	height: 0px;
	margin: 5px;
	background: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 40px;
	border: 0 solid #ccc;
	border-radius: 20px;
	transition: all 0.3s ease-in 0s;
}
.ta-toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-inner {
	margin-left: 0;
}
.ta-toggle-switch-checkbox:checked + .toggle-switch-label .toggle-switch-switch {
	right: 0px;
}

/* Training level heading */
.toggle-training {
	position: relative;
	width: 75px;
	display: inline-block;
	vertical-align: middle;
	text-align: left;
}
.toggle-training-checkbox {
	display: none;
}
.toggle-training-label {
	display: block;
	overflow: hidden;
	cursor: pointer;
	border: 0 solid #ccc;
	border-radius: 20px;
	margin: 0;
}
.toggle-training-inner {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: margin 0.3s ease-in 0s;
}
.toggle-training-inner::before,
.toggle-training-inner::after {
	display: block;
	float: right;
	width: 50%;
	height: 34px;
	padding: 0 8px;
	line-height: 34px;
	font-size: 14px;
	color: white;
	font-weight: bold;
	box-sizing: border-box;
}

.toggle-training-inner:before {
	content: "Yes";
	text-transform: uppercase;
	padding-left: -10px;
	background-color: rgb(92, 184, 92);
	color: #fff;
}
.toggle-training-disabled {
	background-color: #ccc;
	cursor: not-allowed;
}
.toggle-training-disabled::before {
	background-color: #ccc;
	cursor: not-allowed;
}
.toggle-training-inner::after {
	content: "No";
	text-transform: uppercase;
	padding-right: 10px;
	background-color: #ccc;
	color: #fff;
	text-align: right;
}
.toggle-training-switch {
	display: block;
	width: 0px;
	height: 0px;
	margin: 5px;
	background: #fff;
	position: absolute;
	top: 0;
	bottom: 0;
	right: 40px;
	border: 0 solid #ccc;
	border-radius: 20px;
	transition: all 0.3s ease-in 0s;
}
.toggle-training-checkbox:checked
	+ .toggle-training-label
	.toggle-training-inner {
	margin-left: 0;
}
.toggle-training-checkbox:checked
	+ .toggle-training-label
	.toggle-training-switch {
	right: 0px;
}

/* Input box to select MCQ's and videos */
.sub-heading {
	text-align: left;
	font-weight: 600;
	font-size: 18px;
	margin-bottom: 0;
	text-transform: capitalize;
}

.label-text {
	text-align: left;
	font-size: 16px;
	margin-bottom: 0;
	margin-right: 8px;
}

.ml-70 {
	margin-left: 70px;
}

.ml-24 {
	margin-left: 24px;
}

.edit-button {
	font-size: 16px;
	padding: 12px 16px;
	text-transform: capitalize;
	border: none;
	cursor: pointer;
	border-radius: 4px;
	width: 200px;
}

.color-blue {
	color: #3696ff;
}

.bg-white {
	background-color: #fff;
	box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.16);
}

.bg-blue {
	background-color: #3696ff;
	color: #fff;
}

.width-small {
	width: 160px;
}

.mr-100 {
	margin-right: 100px;
}

.separator {
	margin-top: 70vh;
}

.form-wrapper {
	margin: 0px 24px 0px 24px;
	padding: 12px 24px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
	/* display: flex;
	justify-content: space-between; */
	align-items: center;
	cursor: pointer;
	background-color: #fff;
}

.training-levels-mb-0 {
	margin: 24px;
	margin-bottom: 0;
	padding: 24px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
	background-color: #fafafa;
	display: flex;
	justify-content: space-between;
	align-items: center;
	cursor: pointer;
}

.op-50 {
	opacity: 50%;
}

.question-text-wrapper > .question-text {
	color: #000000;
	font-size: 16px;
	line-height: 1.5;
	font-weight: 600;
	margin: 0px 24px;
	padding: 8px 12px;
}
.option-wrapper {
	width: 92%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.option-text {
	margin: 0;
	padding: 12px 8px !important;
	width: 190px !important;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
	background-color: #fafafa;
	cursor: pointer;
	margin-left: 12px;
	font-size: 14px !important;
}
.check-box {
	opacity: 0;
}

.check-box-label {
	margin-left: 24px;
	margin-right: 8px;
	margin-bottom: 24px;
	cursor: pointer;
}

.check-box-label:before {
	content: "";
	height: 24px;
	width: 24px;
	border-radius: 50%;
	display: inline-block;
	top: 5px;
	margin: 0 5px 0 0;
	background-color: transparent;
	border: 2px solid rgba(0, 0, 0, 0.25);
}

input[type="radio"].check-box:checked + .check-box-label::before {
	display: none;
}

input[type="radio"].check-box:checked + .check-box-label:after {
	content: "\2713";
	border-radius: 50%;
	display: inline-block;
	color: #ffffff;
	font-weight: bold;
	height: 28px;
	width: 28px;
	font-size: 24px;
	background-color: #3a9b14;
	text-align: center;
}

.horizontal-line {
	border-top: 2px solid #0000000f;
	margin: 12px 0;
	cursor: none;
}
.mt-54 {
	margin-top: 54px;
}

/* Video edit */
.edit-single-video {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.edit-video-box {
	width: 200px;
	height: 114px;
	background-color: #212121;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.87);
	position: relative;
	margin-right: 32px;
}

.play-icon {
	height: 42px;
	width: 42px;
	background-color: #fff;
	position: absolute;
	border-radius: 50%;
	left: 54px;
	top: 36px;
}

.arrow-right {
	width: 0;
	height: 0;
	border-top: 12px solid transparent;
	border-bottom: 12px solid transparent;
	border-left: 12px solid rgba(0, 0, 0, 0.87);
	margin-left: 16px;
	margin-top: 8px;
}

.toggle-delete-wrapper {
	margin-left: 32px;
	margin-top: 0;
}

.delete-icon {
	margin-left: 14px;
	margin-top: 12px;
	background-color: transparent;
	border: none;
}

.delete-icon > img {
	margin: 0;
	padding: 0;
	width: 16px;
	height: 24px;
	font-size: 100px;
	cursor: pointer;
}

.save-edited {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #b42f20;
	text-transform: uppercase;
	cursor: pointer;
	font-size: 24px;
	color: #ffffff;
	border-radius: 4px;
	outline: none;
	border: 1px solid #b42f20;
	padding: 8px 24px;
	margin-left: 84%;
}

.save-small {
	box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
	background-color: #b42f20;
	text-transform: capitalize;
	cursor: pointer;
	font-size: 16px;
	color: #ffffff;
	border-radius: 4px;
	outline: none;
	border: 1px solid #b42f20;
	padding: 12px 16px;
	margin-left: 84%;
}

.threshold-input-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 32px;
	margin-left: 32px;
}

.threshold-input-wrapper > .threshold-input {
	font-size: 16px;
	padding: 12px 8px;
	margin-top: 12px;
}

.threshold-input-label {
	width: 0;
	height: 0;
	margin: 0;
	margin-right: 12px;
	margin-bottom: 42px;
	padding: 0;
}

.set-false-toggle-value {
	display: block;
	width: 200%;
	margin-left: -100%;
	transition: margin 0.3s ease-in 0s;
}

.set-false-toggle-value::before,
.set-false-toggle-value::after {
	display: block;
	float: left;
	width: 50%;
	height: 28px;
	padding: 0 4px;
	line-height: 28px;
	font-size: 12px;
	color: white;
	font-weight: bold;
	box-sizing: border-box;
}

.set-false-toggle-value:before {
	content: "Yes";
	text-transform: uppercase;
	padding-left: -10px;
	background-color: rgb(92, 184, 92);
	color: #fff;
}

.set-false-toggle-value::after {
	content: "No";
	text-transform: uppercase;
	padding-right: 10px;
	background-color: #ccc;
	color: #fff;
	text-align: right;
}
.toggle-training-checkbox:checked
	+ .toggle-training-label
	.set-false-toggle-value {
	margin-left: 0;
}

.mr-32 {
	margin-right: 32px;
}

.reset-button {
	margin-left: auto;
	margin-right: 32px;
	border-radius: 4px;
	background-color: #3696ff;
	color: #fff;
	text-transform: capitalize;
	font-size: 18px;
	padding: 8px 12px;
	border: none;
	cursor: pointer;
}
.threshold-label{
	white-space: nowrap;
	font-weight: 600;
	margin: 0 !important;
	margin-right: 4px !important;
}
.disable-training-levels{
	background-color: #d6cdcd!important;
    color: #666!important;
    cursor: not-allowed !important;
}
.disabled-cursor{
    cursor: not-allowed !important;
}