::-webkit-scrollbar {
    width: 0px;
    background: transparent; /* make scrollbar transparent */
}


#dashboardmaindiv{
	min-width:100%;
	max-width:100%;
	height:100%;
	margin:auto;
	clear:both;
	text-align:center;
	overflow:hidden;
	position: relative;
}

.width20pc{
	width : 20%;
}

.leftmain{
	width:18%;
	height:100%;
/* //	display: inline-block; */
	float: left;
	overflow:hidden;
	overflow-y: scroll;
/* //	border-right:1px solid #dddddd; */
}
.middlemain{
	width: calc(60% - 10px);
	height:100%;
/* //	display: inline-block; */
	float: left;
	overflow:hidden;
	position: relative;
	margin-left: 5px;
	margin-right: 5px;
}

#dashboardchatmainright{
	width: 22%;
	height:100%;
/* //	display: inline-block; */
	float: right;
	overflow-x:hidden;
	overflow-y:auto;
	position: relative;
}


.leftmain > div.first{
	width:100%;
	padding: 10px;
	left:0;
  	top:0px;
  	height:80px;
}
.leftmain > div.second{
	width:100%;
	padding: 0px 10px;
	height: 30px;
	clear:both;
	overflow: hidden;
	left:0;
  	top:80px;
}
.leftmain > div.second div.choicethreads{
	width:auto;
	height: 30px;
	overflow: hidden;
	border : 1px solid #e73c33;
	border-radius: 5px;
	-webkit-border-radius: 5px;
	font-size: 11px;
}
.leftmain > div.second div.choicethreads div.choicethreadsinner{
	width:auto;
	height: 30px;
	overflow: hidden;
	padding: 0px 10px;
	line-height: 30px;
	background: #ffffff;
	color: #e73c33;
	cursor: pointer;
}
.leftmain > div.second div.choicethreads div.choicethreadsinner.selected{
	background: #e73c33;
	color: #ffffff;
}
.leftmain > div.second div.choicethreads div.choicethreadsinner:hover{
	background: #e73c33;
	color: #ffffff;
}
.leftmain > div.second div.choicethreads div.choicethreadsinner.selected:hover{
	background: #e73c33;
	color: #ffffff;
}
.leftmain > div.third{
	width:100%;
	padding: 0px;
	clear:both;
	overflow-y: auto;
	overflow-x: visible;
	left:0;
  	top:140px;
  	/*bottom:170px;*/
  	bottom:0px;
}

.thread{
	width:100%;
	height:80px;
/*	border-top:1px solid #eeeeee;
	border-bottom:1px solid #eeeeee;
	border-left: 3px solid #f2f2f2;*/
	text-align: center;
	padding:0px 10px;
	width:100%;
	cursor:pointer;
	margin-top: 5px;
	margin-bottom: 5px;
	box-shadow: 0px 1px 2px rgba(50, 50, 50, .2);
	moz-box-shadow:    0px 1px 2px rgba(50, 50, 50, .2);
	-webkit-box-shadow: 0px 1px 2px rgba(50, 50, 50, .2);
	text-overflow: ellipsis;
	white-space: nowrap;
  	/* white-space: nowrap; */
}
.thread:not(.selected):hover{
	background:#fcfcfc;
	color:#565656;
	font-family: 'hurme1semibold';
	box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/*	border-left: 3px solid #348dd8;*/
}
.thread.selected{
	background:#fcfcfc;
	color:#e73c33;
	font-family: 'hurme1bold';
	box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/*//	border-left: 3px solid #348dd8;
//	border-top:1px solid #dddddd;
//	border-bottom:1px solid #dddddd;*/
}
.thread table{
	text-align: left;
}


.thread .code{
	width:100%;
	height:30px;
	font-size: .9rem;
}
.thread .code span.new{
	width:auto;
	height:auto;
	font-size: .4rem;
	padding : 3px 7px;
}
.thread .people{
	width:100%;
	height:20px;
	font-size: .7rem;
}
.thread .msg{
	width:100%;
	height:30px;
	font-size: .7rem;
}


.chatinput {
	position: absolute;
	left:0;bottom:0;right:0;
    width:100%;
    height: 60px;
}
.chatinput > div{
	position: relative;
    width:100%;
    height: 100%;
}
.chatinput .aibuttonbox{
	position: absolute;
	left:0;top:0;bottom:0;
    height: 100%;
    width: 60px;
    padding: 0 5px 0 10px;
}
.chatinput .inputbuttonbox{
	position: absolute;
	right:0;top:0;bottom:0;
    height: 100%;
    width: 90px;
    padding: 0 5px 0 5px;
}
.chatinput .inputbox{
	overflow: visible;
	position: absolute;
	left:60px;right:90px;top:0;bottom:0;
    height: 100%;
    padding: 0 5px 0 10px;
}
.chatinput .inputbox #sendie{
	margin: 0;
	height:40px;
/*	height: 100%;
	width:100%;
	max-width:100%;
    font-size:1rem;line-height:60px;*/
	/*border-radius:0;-webkit-appearance:none;-moz-appearance:none;appearance:none;
	outline:0;color:#565656;margin:0 0 0;
	-webkit-transition:box-shadow 70ms ease-out,border-color 70ms ease-out;
	-moz-transition:box-shadow 70ms ease-out,border-color 70ms ease-out;
	transition:box-shadow 70ms ease-out,border-color 70ms ease-out;
	-moz-osx-font-smoothing:grayscale;
	box-shadow:none;height:auto;
	-webkit-box-sizing: border-box !important;
	-moz-box-sizing: border-box !important;
	box-sizing: border-box !important;*/
}

.chat{
	width:100%;
	position:absolute;
	top:0;left:0;bottom:65px;
	clear:both;
	overflow:hidden;
	overflow-y: auto;
/* //	border-bottom: 1px solid #dddddd; */
}

.chattyping{
	width:100%;
	position:absolute;
	top:0;left:0;
	z-index : 2;
	padding: 10px 10px 5px;
	font-family: 'hurme1bold';
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 10px;
/* //	border-bottom: 1px solid #dddddd; */
}

.chatai{
	width:100%;
	position:absolute;
	z-index: 3;
	top:0;left:0;bottom:65px;
	clear:both;
	overflow:hidden;
	overflow-y: auto;
	display: none;
/* //	border-bottom: 1px solid #dddddd; */
}
.chatai.showing{
	display: block;
}

.chataitop{
	width:100%;
	padding: 10px 10px 5px;
	font-family: 'hurme1bold';
	text-transform: uppercase;
	font-size: 14px;
	margin-bottom: 10px;
/* //	border-bottom: 1px solid #dddddd; */
}

.aimessage{
	width:100%;
	height: auto;
	padding: 10px 20px;
}
.aimessage > div{
	width:auto;
	max-width: 80%;
	height: auto;
	overflow: visible;
	float: left;
}
.aimessage > div .text{
	cursor: pointer;
	height:auto;
	width: auto;
	min-width: 0px;
	padding: 10px 10px;
	font-size: 14px;
	font-family: 'hurme1ob';
	border-top-left-radius: 0px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    background: #f2f2f2;
    color:#444444;
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
	white-space: pre-line;
}

.aimessage > div .text.notification{
	font-family: 'hurme1boldob';
	background: #e73c33;
    color:#ffffff;
    text-transform: uppercase;
}

.message{
	width:100%;
	height: auto;
	padding: 5px 40px;
}
.message > .divider{
	width:100%;
	height: auto;
	display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

	align-items: center;
	
	flex-direction: row;
}
.message > .divider .dividerline{
	/* width:100%; */
	flex : 1;
	height: 1px;
	/* display: inline-block; */
}
.message > .divider .dividertext{
	width: auto;
	padding: 0 40px;
	/* width:100%; */
	/* height: 2px; */
	/* display: inline-block; */
}
.message > .self{
	width:auto;
	max-width: 80%;
	height: auto;
	overflow: visible;
	float: right;
	display: -ms-flexbox;
    display: -webkit-flex;
	display: flex;
	justify-content: flex-end;
	flex-direction: column;
}
.message > .others{
	width:auto;
	max-width: 80%;
	height: auto;
	overflow: visible;
	float: left;
	display: -ms-flexbox;
    display: -webkit-flex;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
}

.message .buffer{
	height:15px;
	width:100%;
}

.message .sender{
	height:auto;
	width:auto;
	padding-top:15px;
	display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    -ms-flex-align: center;
    -webkit-align-items: center;
    -webkit-box-align: center;

	align-items: center;
	
	flex-direction: row;
}
.message .self .sender{
	justify-content: flex-end;
}
.message .others .sender{
	justify-content: flex-start;
}
.message .sender > div{
	height:auto;
	width:auto;	
	/* display: inline-block; */
}
.message .sender > .sendertext{
	padding:0 20px 10px;
}
.message .sender > .sendertext > .sendername{
	font-size:14px;
	font-weight: 500;
	font-family: 'hurme1bold';
}
.message .sender > .sendertext > .sendertime{
	padding-top: 5px;
	font-size:11px;
	font-family: 'hurme1ob';
}
.message .sender > .senderimage{
	padding-bottom: 5px;
}
.message .sender > .senderimage > img{
	border-radius: 50%;
	background-position: 50%;
}


.message .self .textNotif{
	height:auto;
	width: auto;
	max-width: auto;
	min-width: 0px;
	font-size: 14px;
	border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    background: #f9f9f9;
    color:#444444;
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
}
.message .self .textNotifHeader{
	height:auto;
	width: 100%;
	max-width: auto;
	min-width: 0px;
	background: #e73c33;
    color:#ffffff;
    padding: 5px 10px;
    font-size: 12px;
    text-transform: uppercase;
    font-family: 'hurme1bold';
}
.message .self .textNotifMessage{
	height:auto;
	width: auto;
	max-width: auto;
	min-width: 0px;
    padding: 10px 10px;
    white-space: pre-line;
}


.message .self .text{
	/* flex: 1; */
	/* display:inline-block; */
	height:auto;
	width:auto;
	/* width: fit-content; */
	/* flex: 1; */
	max-width: 100%;
	min-width: 0px;
	padding: 10px 10px;
	font-size: 14px;
	border-top-left-radius: 20px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 20px;
    -webkit-border-top-right-radius: 0px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    /* background: #f9f9f9; */
    /* color:#444444; */
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
	white-space: pre-line;
}

.message .others .text{
	/* display:table; */
	height:auto;
	width:auto;
	/* width: fit-content; */
	max-width: 100%;
	min-width: 0px;
	padding: 10px 10px;
	font-size: 14px;
	border-top-left-radius: 0px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    -webkit-border-top-left-radius: 0px;
    -webkit-border-top-right-radius: 20px;
    -webkit-border-bottom-left-radius: 20px;
    -webkit-border-bottom-right-radius: 20px;
    /* background: #f2f2f2; */
    /* color:#444444; */
    box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
	moz-box-shadow:    0px 2px 5px rgba(50, 50, 50, .3);
  	-webkit-box-shadow: 0px 2px 5px rgba(50, 50, 50, .3);
/* //    box-shadow: 0 15px 35px rgba(50, 50, 93, .1), 0 5px 15px rgba(0, 0, 0, .07); */
	white-space: pre-line;
}

/* Mobile */
		@media only screen and (max-device-width: 480px) {
			 						
						.leftmain{
							width: 100%;
							height:100%;
							display:block;
						}
						.thread{
							
							width: 100%;
							text-align: center;
						}
						.middlemain{
							width: 100%;
							height:100%;
							display: none;
						}

						.chatinput .backbuttonbox{
							position: absolute;
							left:0;top:0;bottom:0;
						    height: 100%;
						    width: 90px;
						    padding: 0 5px 0 10px;
						}
						.chatinput .aibuttonbox{
							position: absolute;
							left:90px;top:0;bottom:0;
						    height: 100%;
						    width: 60px;
						    padding: 0 5px 0 5px;
						}
						.chatinput .inputbuttonbox{
							position: absolute;
							right:0;top:0;bottom:0;
						    height: 100%;
						    width: 90px;
						    padding: 0 10px 0 5px;
						}
						.chatinput .inputbox{
							position: absolute;
							left:150px;right:90px;top:0;bottom:0;
						    height: 100%;
						    padding: 0 5px 0 5px;
						}


				}	

