.inputContainer {
  height: 48px;
  border-radius: 10px;
  border: solid 1px #d0d0d0;
  background-color: rgba(255, 255, 255, 0.36);
  position: relative;
  padding: 0 8px;
}

.x2-height {
  height: 96px !important;
}

.x2-mainInput {
  height: auto !important;
  resize: none;
}
.inputTitle{
  font-size: 14px;
  position: absolute;
  transform-origin: top left;
  transform: translate(0, 16px) scale(1);
  transition: all .1s ease-in-out;
  color: #9b9b9b;
}
.inputContainer.active .inputTitle {
  transform: translate(0, 4px) scale(.75);
}
.mainInput {
  margin: 16px 0 10px;
  width: 90%;
  height: 16px;
  font-size: 14px;
  color: #000000;
  padding-top:6px;
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
}

.uppercase-class {
  text-transform: uppercase;
}

.error{
  border: solid 1px #ff4355;
}

.helperText{
  position: absolute;
    z-index: 10;
    font-size: 12px;
    top: 50px;
    color: #ff4355;
}
.custom-input .errorText {
    color: red;
    font-size: smaller;
    font-weight: 100;
}