.pageBanner {
  display: inline-block !important;
  width: 40% !important;
  margin: 1rem 2rem !important;
}
.catVideos{
  display: inline-block !important;
  margin: 1rem 2rem !important;
}
.manageTimeSlot {
  border: 1px solid #cccccc;
  background: #f6f6f6;
  height: auto;
  display: grid;
  align-items: center;
  padding: 0rem 1rem;
  border-bottom: none;
  font-size: 1rem;
  font-weight: 650;
  text-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.9px;
  padding: 1rem;
}
.innerBox {
  border: 1px solid #cccccc;
  padding: 1rem;
  display: grid;
}
.serviceTagContainer {
  width: -webkit-fill-available;
  height: fit-content;
  border: 1px solid #dddddd;
  border-radius: 0.3125rem;
  background: #f6f6f6;
  padding: 1.5rem;
}
.order-refund {
  display: flex;
  justify-content: space-around;
  width: -webkit-fill-available;
  height: fit-content;
  border: 1px solid #dddddd;
  border-radius: 0.3125rem;
  background: #f6f6f6;
  padding: 1.5rem;
}
.serviceTagDetailsContainer {
  color: #282828;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.0088rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.maskingWalletContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.radioContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.monthName {
  color: #008000;
  padding: 0rem 1rem;
  margin-right: auto;
}
.dateBox {
  width: 3.5rem;
  height: 3.0625rem;
  background: #ffffff;
  border-radius: 0.125rem;
  color: #282828;
  display: grid;
  align-items: center;
  justify-items: center;
  font-size: 0.7rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.0075rem;
  margin: 2rem 6.4rem 0rem 0rem;
}
.dateBoxColor {
  background: #c5322c !important;
  color: #ffffff !important;
  font-weight: 600;
}
.timingSlot {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  border-bottom: 2px solid #dddddd;
}
.timingSlot:last-child {
  border-bottom: none;
}
.timeBox {
  width: 7rem;
  height: 2.2rem;
  background: #ffffff;
  border-radius: 0.125rem;
  font-size: 0.85rem;
  letter-spacing: 0.14px;
  color: #000;
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  margin: 2rem 3rem 0rem 0rem;
}
.timeBoxColor {
  background: #c5322c !important;
  color: #ffffff !important;
}
.saveBtn {
  /* background: rgb(105, 118, 148);
  color: #fff; */
  color: rgb(255, 255, 255);
  background-color: rgb(92, 184, 92);
  border-color: rgb(76, 174, 76);
}
.dateTextBox {
  margin: 2rem 0.625rem 0rem 0rem;
  width: 60%;
}
.dateInputBox {
  background: #ffffff;
  border-radius: 0.125rem !important;
  color: #282828 !important;
  display: grid;
  align-items: center;
  justify-items: center;
}

.modal-sb {
  position: fixed;
  z-index: 5;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 150px;
  bottom: 0;
}

.sub-modal-sb {
  position: fixed;
  z-index: 6;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  left: 54%;
  top: 48%;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #e73c33;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
input[readonly] {
  background: #e8e8e8 !important;
}
label {
  font-size: 15px !important;
}

.milesTable {
  width: 100%;
}
.milesTable caption {
  text-align: left;
  margin: 1rem 0;
}
.milesTable tr th {
  background: rgb(105, 118, 148);
  color: #ffffff;
}
.milesTable tr th,
.milesTable tr td {
  padding: 1rem;
}
.message {
  padding: 0 !important;
}

.video-card {
  position: relative;
  height: auto;
  width: 20rem;
  border-radius: 30px;
  background: #e0e0e0;
  box-shadow: 10px 10px 20px #a8a8a8, -10px -10px 20px #ffffff;
  display: inline-block;
  margin: 1rem;
  padding-bottom: 2rem;
}
.closeVideoCard{
position: absolute;
    right: -1rem;
    width: 30px;
    border-radius: 100% !important;
    background: linear-gradient(315deg, #e73c33, #c5322c)!important; 
    height: 30px;
    cursor: pointer !important;
}
.closeVideoCard:hover{
  cursor: pointer;
  background: linear-gradient(315deg, #c5322c, #e73c33)!important; 
}
.category-card{
  height: auto;
  width: inherit;  
  border-radius: 30px;
  background: #e0e0e0;
  box-shadow: 10px 10px 20px #a8a8a8, -10px -10px 20px #ffffff;
  /* display: inline-block; */
  margin: 1rem;
  padding-bottom: 2rem;
}
.video-card img {
  width: inherit;
  border-radius: 30px 30px 0 0
}
.category-card img{
  width: 100%;
  border-radius: 30px 30px 0 0;
}

.video-card div,.category-card div{
  padding: 1rem;
}
.video-card button,.category-card button,.button-update{
  border-radius: 10px;
  background: linear-gradient(315deg, #3f8d40, #4cb050);
  color: #fff;
  border-style: none;
  padding:10px;
  float: right;
  margin-right: 1rem;
}
video{
  width: inherit;
}