.mainContainer {
  display: grid;
  width: 70%;
  margin: 4rem auto;
}
.manageTimeSlot {
  border: 1px solid #cccccc;
  background: #f6f6f6;
  height: 2rem;
  display: grid;
  align-items: center;
  padding: 0rem 1rem;
  border-bottom: none;
  font-size: 1rem;
  font-weight: 650;
  text-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.9px;
}
.innerBox {
  border: 1px solid #cccccc;
  padding: 2rem;
  display: grid;
}
.walletContainer {
  width: -webkit-fill-available;
  height: fit-content;
  border: 1px solid #dddddd;
  border-radius: 0.3125rem;
  background: #f6f6f6;
  padding: 1.5rem;
}
.walletDetailsContainer {
  color: #282828;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.0088rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.maskingWalletContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.radioContainer{
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.monthName {
  color: #008000;
  padding: 0rem 1rem;
  margin-right: auto;
}
.dateBox {
  width: 3.5rem;
  height: 3.0625rem;
  background: #ffffff;
  border-radius: 0.125rem;
  color: #282828;
  display: grid;
  align-items: center;
  justify-items: center;
  font-size: 0.7rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.0075rem;
  margin: 2rem 6.4rem 0rem 0rem;
}
.dateBoxColor {
  background: #c5322c !important;
  color: #ffffff !important;
  font-weight: 600;
}
.timingSlot {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  border-bottom: 2px solid #dddddd;
}
.timingSlot:last-child {
  border-bottom: none;
}
.timeBox {
  width: 7rem;
  height: 2.2rem;
  background: #ffffff;
  border-radius: 0.125rem;
  font-size: 0.85rem;
  letter-spacing: 0.14px;
  color: #000;
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  margin: 2rem 3rem 0rem 0rem;
}
.timeBoxColor {
  background: #c5322c !important;
  color: #ffffff !important;
}
.saveBtn {
  /* background: rgb(105, 118, 148);
  color: #fff; */
  color: rgb(255, 255, 255);
  background-color: rgb(92, 184, 92);
  border-color: rgb(76, 174, 76);
}
.dateTextBox{
  margin: 2rem 0.625rem 0rem 0rem;
  width: 60%;
}
.dateInputBox{
  background: #ffffff;
  border-radius: 0.125rem !important;
  color: #282828 !important;
  display: grid;
  align-items: center;
  justify-items: center;
}

.modal-sb {
  position: fixed;
  z-index: 5;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 150px;
  bottom: 0;
}

.sub-modal-sb {
  position: fixed;
  z-index: 6;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  left: 54%;
  top: 48%;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #e73c33;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* file uploader */
.drag-area{
  height: 500px;
  width: 700px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 50px;
  background: #e0e0e0;
  box-shadow:  20px 20px 60px #bebebe,-20px -20px 60px #ffffff;
}
.drag-area.active{
  border: 2px solid #fff;
}
.drag-area button{
  padding: 10px 25px;
  font-size: 20px;
  font-weight: 500;
  border: none;
  outline: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 1rem;
}
.drag-area img{
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
/* file uploader end */
.green-pill-box{
  background: green !important;
  color: #fff !important;
  border-radius: 1rem !important;
}
.required{
  color: #e73c33;
  padding-left: 5px;
  position: relative;
  bottom:15px;
}