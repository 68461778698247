.table-bordered {
    border: 1px solid #ddd;
}
.table{
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
}
table {
    background-color: transparent;
}
table {
    border-spacing: 0.5rem;
    border-collapse: collapse;
}
.row {
    margin-right: -15px;
    margin-left: -15px;
}
.col-xs-12 {
    width: 100%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}
.modal-cc-sb{
    position: fixed;
    z-index: 5;
    background: rgba(0, 0, 0, 0.6);
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    /* top: 150px; */
    bottom: 0;
}
