.mainContainer {
  display: grid;
  width: 70%;
  margin: 4rem auto;
}
.manageTimeSlot {
  border: 1px solid #cccccc;
  background: #f6f6f6;
  height: 2rem;
  display: grid;
  align-items: center;
  padding: 1rem !important;
  border-bottom: none;
  font-size: 1rem;
  font-weight: 650;
  text-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.9px;
}
.innerBox {
  border: 1px solid #cccccc;
  padding: 1rem;
  display: grid;
}
.serviceTagContainer {
  width: -webkit-fill-available;
  height: fit-content;
  border: 1px solid #dddddd;
  border-radius: 0.3125rem;
  background: #f6f6f6;
  padding: 1.5rem;
}
.serviceTagDetailsContainer {
  color: #282828;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.0088rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.maskingWalletContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.radioContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.monthName {
  color: #008000;
  padding: 0rem 1rem;
  margin-right: auto;
}
.dateBox {
  width: 3.5rem;
  height: 3.0625rem;
  background: #ffffff;
  border-radius: 0.125rem;
  color: #282828;
  display: grid;
  align-items: center;
  justify-items: center;
  font-size: 0.7rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.0075rem;
  margin: 2rem 6.4rem 0rem 0rem;
}
.dateBoxColor {
  background: #c5322c !important;
  color: #ffffff !important;
  font-weight: 600;
}
.timingSlot {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  border-bottom: 2px solid #dddddd;
}
.timingSlot:last-child {
  border-bottom: none;
}
.timeBox {
  width: 7rem;
  height: 2.2rem;
  background: #ffffff;
  border-radius: 0.125rem;
  font-size: 0.85rem;
  letter-spacing: 0.14px;
  color: #000;
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  margin: 2rem 3rem 0rem 0rem;
}
.timeBoxColor {
  background: #c5322c !important;
  color: #ffffff !important;
}
.saveBtn {
  /* background: rgb(105, 118, 148);
  color: #fff; */
  color: rgb(255, 255, 255);
  background-color: rgb(92, 184, 92);
  border-color: rgb(76, 174, 76);
}
.dateTextBox {
  margin: 2rem 0.625rem 0rem 0rem;
  width: 60%;
}
.dateInputBox {
  background: #ffffff;
  border-radius: 0.125rem !important;
  color: #282828 !important;
  display: grid;
  align-items: center;
  justify-items: center;
}

.modal-sb {
  position: fixed;
  z-index: 5;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  top: 150px;
  bottom: 0;
}

.sub-modal-sb {
  position: fixed;
  z-index: 6;
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  left: 54%;
  top: 48%;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #e73c33;
  animation: spin 2s linear infinite;
}
.accesooriesSectionInfoTitle {
  font-size: 1.25rem;
  line-height: 2;
  padding: 0.75rem 0.75rem 0.75rem 0rem;
  margin-bottom: 2rem !important;
}

.warranty-carousel-title{
  font-size: 1rem; 
  font-weight: 650;
  text-shadow: none; 
  height: 2rem; 
  display: grid;
  align-items: center;
  padding: 1rem;
}

.warranty-carousel-image-container {
  width: 50%; 
  height: 50%; 
  display: grid; 
  grid-template-columns: 1fr 1fr; gap: 1rem 1rem;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
input[readonly] {
  background: #e8e8e8 !important;
}
lable{
  font-size:15px !important;;
}
hr {
  border:none;
  height: 20px;
 	width: 100%;
	height: 50px;
	margin-top: 0;
	border-bottom: 1px solid transparent;
	box-shadow: 0 20px 20px -20px #333;
  margin: 10px auto 30px; 
}
.closeButton {
    float: right;
    width: 20px;
    border-radius: 100% !important;
    background: linear-gradient(315deg, #e73c33, #c5322c)!important;
    height: 20px;
    color: white;
    border: none;
    cursor: pointer;
}

.inputKeys {
  background: none !important;
  border-radius: unset !important;
  outline: none !important;
  margin: 0 !important;
  box-shadow: none !important;
  padding-left: 0 !important;
  padding-top: 0 !important;
  /* width: auto !important; */
  /* text-transform: capitalize */
}
.inputKeys + img {
  height: 1em;
}

.modal{
  position:fixed;
  display: none;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.6);
}
.modal-container{
  width: 50vw !important;
  margin: 15rem auto !important;
  background-color: #e8e8e8;
}
.modal-buttons{
border-radius: 10px;
  color: #fff;
  border-style: none;
  padding:10px;
  float: right;
  margin-right: 1rem;
  width: 5rem;
  display: inline;
}
.modal-buttons:hover{
  cursor: pointer;
}
.button-delete{
  
  background: linear-gradient(315deg, #e73c33, #c5322c)!important;
}
.button-delete:hover{
  background: linear-gradient(315deg, #c5322c, #e73c33)!important;
}
.button-basic{
  color: #000;
  background: linear-gradient(315deg, #cccccc,#dddddd)!important;
}
.button-basic:hover{
  background: linear-gradient(315deg, #dddddd,#cccccc)!important;
}
.wp-modal-sb {
  position: fixed;
  z-index: 5;
  background: rgba(0, 0, 0, 0.6);
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* top: 150px; */
  bottom: 0;
}