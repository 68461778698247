.form-wrapper-video {
	margin: 0 24px 0px 24px;
	padding: 12px 24px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
	align-items: center;
	cursor: pointer;
	background-color: #fff;
}

.edit-form-video{
    width:20%;
    margin-right:20px;
    margin-top: 0;
}

.edit-video-box-video {
	width: 500px;
	height: 130px;
	background-color: #212121;
	border-radius: 4px;
	background-color: rgba(0, 0, 0, 0.87);
	position: relative;
	margin-right: 32px;
}

.flex-video {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.select-label-video {
    /* font-weight: 600; */
    font-size: 14px !important;
}
.dropdown-image-video {
    font-size: 16px !important;
    padding: 12px !important;
}

.dropdown-image-video > option {
    font-size: 16px !important;
    padding: 12px !important;
}

.label-sub-heading-video {
    font-size: 14px !important;
}

.upload-file {
    font-size: 14px !important;
    cursor: pointer !important;
    /* border: 1px solid #ccc !important; */
    display: inline-block !important;
    padding: 8px 0px !important;
    line-height: 1.5 !important;
    width: 200px !important;
    
}

.question-text-video {
    font-size: 16px !important;
    padding: 12px 8px !important;
    margin-right: 16px !important;
}

.mt-negative-38 {
    margin-top: -38px !important;
}

.toggle-delete-wrapper-video {
	width: 150px;
}
.save-button-video {
    width: 120px;
    font-size: 16px;
    padding: 8px;
    margin-left: 0;
    margin-top: 24px;
}
.delete-icon-video {
    margin-top: 0;
    margin-left: 0px;
	background-color: transparent;
	border: none;
}

.delete-icon-video > img {
	margin: 0;
	padding: 0;
	width: 16px;
	height: 24px;
	font-size: 100px;
	cursor: pointer;
}

.video-edit-final-child:last-child {
    margin-bottom: 100px;
}
.validation-warning{
    color:red;
    font-size: 12px;
    margin-left:20px;
}