.mainContainer {
  display: grid;
  width: 70%;
  margin: 4rem auto;
}
.manageTimeSlot {
  border: 1px solid #cccccc;
  background: #f6f6f6;
  height: 2rem;
  display: grid;
  align-items: center;
  padding: 0rem 1rem;
  border-bottom: none;
  font-size: 1rem;
  font-weight: 650;
  text-shadow: 0 3px 1px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.9px;
}
.innerBox {
  border: 1px solid #cccccc;
  padding: 2rem;
  display: grid;
}
.slotBookContainer {
  width: -webkit-fill-available;
  height: fit-content;
  border: 1px solid #dddddd;
  border-radius: 0.3125rem;
  background: #f6f6f6;
  padding: 1.5rem;
}
.selectDate {
  color: #282828;
  font-size: 1rem;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.42;
  letter-spacing: 0.0088rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}
.monthName {
  color: #008000;
  padding: 0rem 1rem;
  margin-right: auto;
}
.dateBox {
  width: 3.5rem;
  height: 3.0625rem;
  background: #ffffff;
  border-radius: 0.125rem;
  color: #282828;
  display: grid;
  align-items: center;
  justify-items: center;
  font-size: 0.7rem;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.46;
  letter-spacing: 0.0075rem;
  margin: 2rem 6.4rem 0rem 0rem;
}
.dateBoxColor {
  background: #c5322c !important;
  color: #ffffff !important;
  font-weight: 600;
}
.timingSlot {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 1rem;
  border-bottom: 2px solid #dddddd;
}
.timingSlot:last-child {
  border-bottom: none;
}
.timeBox {
  width: 7rem;
  height: 2.2rem;
  background: #ffffff;
  border-radius: 0.125rem;
  font-size: 0.85rem;
  letter-spacing: 0.14px;
  color: #000;
  display: grid;
  align-items: center;
  justify-items: center;
  cursor: pointer;
  margin: 2rem 3rem 0rem 0rem;
}
.timeBoxColor {
  background: #c5322c !important;
  color: #ffffff !important;
}
.saveBtn {
  /* background: rgb(105, 118, 148);
  color: #fff; */
  color: rgb(255, 255, 255);
  background-color: rgb(92, 184, 92);
  border-color: rgb(76, 174, 76);
}
.dateSlot {
  display: flex;
  flex-wrap: wrap;
  /* margin: 2rem 0.625rem 0rem 0rem; */
}
.dateTextBox{
  margin: 2rem 0.625rem 0rem 0rem;
  width: 60%;
}
.dateInputBox{
  background: #ffffff !important;
  border-radius: 0.125rem !important;
  color: #282828 !important;
  display: grid;
  align-items: center;
  justify-items: center;
}
.inputDisabled{
  background-color: #ccc !important; 
  color: #666;
  cursor: not-allowed;
}

.toggle-input-sb {
  display: flex;
  justify-content: space-between;
  margin-top: 1.75rem;
}
.dateLockContainer{
    width: 60%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
}