.training-levels {
	margin: 16px 32px;
	padding: 4px 20px;
	border-radius: 4px;
	box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);
	background-color: #fafafa;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 60px;
	cursor: pointer;
}

.add-next-level {
	display: flex;
	justify-content: flex-end;
}

.add-next-level > button {
	margin-left: auto;
	margin-right: 32px;
	border-radius: 4px;
	background-color: #3696ff;
	color: #fff;
	text-transform: capitalize;
	font-size: 18px;
	padding: 8px 12px;
	border: none;
	cursor: pointer;
}

.select-training-level {
	font-size: 22px;
	font-weight: 700;
	margin: 0;
}

.training-levels-final-child:last-child {
	margin-bottom: 100px;
}

.select-next {
	font-size: 32px;
	font-weight: 700;
}


.single-option {
	margin-top: 0px;
}

.mt-32 {
	margin-top: 32px;
}

.mcq-questions-final-child:last-child {
	margin-bottom: 100px;
}
.validation-warning{
    color:red;
    font-size: 12px;
    margin-left:20px;
}