.lightGrayBackground {
    background-color: #f3f3f3 !important;
}
.row-5{
    display: -ms-grid;
    display: grid;
    -ms-grid-columns:repeat(5,20%);
    grid-template-columns: repeat(5,20%);
    }
.margin_10{
    margin:10px !important;
    }   
.margin_r_16 {
    margin-right: 16px;
    }
.margin_b_20 {
    margin-bottom: 20px;
    } 
.margin_b_10 {
    margin-bottom: 10px;
}           